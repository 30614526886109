/* eslint-disable */
import Vue from "vue"
import { useStore } from "@/store/store.js"
import { weekNames } from "@/constants/dates"
const store = useStore()

export default {
  methods: {
    async getMe() {
      await store.dispatch("getMe");
      const { user } = await store.getters;
      await this.setMeOptions(user);
      this.setaOSaldo();
      return user;
    },

    async setMeOptions(personalData) {
      const { customerPlan } = personalData;
      const { partnerId, planServices } = customerPlan;

      this.$session.set("name", personalData.name);
      this.$session.set("photo", personalData.photo);
      if (personalData.type === "PATIENT") {
        this.$session.set("type", "Cliente");
      }

      this.$session.set("customerData", personalData);
      this.$session.set("address", personalData.address);
      this.$session.set("cpf", personalData.cpf);
      this.$session.set("email", personalData.email);
      this.$session.set("name", personalData.name);
      this.$session.set("filooCard", personalData.filooCard);
      this.$session.set("customerId", personalData.customerId);
      this.$session.set("id", personalData.id);
      this.$session.set("planValue", personalData.planValue);
      this.$session.set("planTypeId", personalData.planTypeId);
      this.$session.set("customerPlan", personalData.customerPlan);
      this.$session.set("signatureId", personalData.signatureId);
      this.$session.set("projectPhoto", personalData.projectPhoto);
      this.$session.set("additionalLife", personalData.additionalLife);
      this.$session.set("patientId", personalData.patientId);
      this.$session.set("planServices", customerPlan.planServices);
      this.$session.set("partnerId", customerPlan.partnerId);
      this.$session.set(
        "isProject",
        personalData.hasOwnProperty("isProject")
          ? personalData.isProject
          : false
      );
      this.$session.set("isB2B", personalData.isB2B);
      localStorage.removeItem("planAndAddOnsData");

      if (partnerId) {
        this.setPlanServices([
          ...planServices,
          { route: "/solicitation-and-exams" },
        ]);
        this.setServicesNameForValidation(planServices);
      } else {
        await store.dispatch("getUserServices", personalData.patientId);
        const servicesRouteList = await store.getters.userServices;

        this.setRouteList([...servicesRouteList, "/solicitation-and-exams"]);
        this.setServicesNameForValidation(servicesRouteList);
      }
    },

    setRouteList(services) {
      const servicesRoute = services
        .filter((srvc) => srvc.route != null)
        .map((srvc) => srvc.route);

      this.$session.set("planServices", services);
      this.$session.set("servicesRoutes", servicesRoute);
    },

    setaOSaldo() {
      this.axios
        .get(`${this.$store.state.filooServer}wallet/overview`, {
          headers: { Authorization: this.$session.get("bearer") },
        })
        .then(({ data }) => this.$session.set("saldo", data.avaliableBalance));
    },

    setPlanServices(planServices) {
      const servicesRoutePlan = planServices
        .filter((srvc) => srvc.route != null)
        .map((srvc) => srvc.route);

      this.$session.set("planServices", planServices);
      this.$session.set("servicesRoutes", servicesRoutePlan);

      return servicesRoutePlan;
    },

    setServicesNameForValidation(services) {
      const servicesNames = services
        .filter((plan) => plan.active)
        .map((plan) => plan.name);

      this.$session.set("servicesNames", servicesNames);
    },

    showNotifyWarning(msg, time) {
      Vue.prototype.$vs.notify({
        title: "Atenção",
        text: msg,
        color: "warning",
        icon: "error_outline",
        time: time,
        position: "top-right",
      });
    },

    whatsAppSupport(msg) {
      window.open(`https://wa.me/55011996507610?text=${msg}`);
    },

    showNotifyError(msg, time) {
      Vue.prototype.$vs.notify({
        title: "Atenção",
        text: `${msg}. Entre em contato com a equipe de atendimento para
          informar o ocorrido através do e-mail: contato@filoo.com.br ou
          pelo whatsapp 11 97464-0527.`,
        color: "danger",
        click: () => {
          window.open(
            "https://api.whatsapp.com/send?phone=55011974640527&text=Sua%20mensagem%20aqui",
            "_blank"
          );
        },
        time: time || 9000,
        icon: "cancel",
        position: "top-right",
      });
    },

    showNotifySuccess(title, msg, time) {
      Vue.prototype.$vs.notify({
        title: title,
        text: msg,
        color: "success",
        icon: "verified_user",
        time: time,
        position: "top-right",
      });
    },

    maskMoney(value) {
      if (!value) {
        return "R$ 0,00";
      }

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      }).format(parseFloat(value));
    },

    maskPhone(__phone) {
      var pt = /[()\- ]+/gi;
      __phone = __phone.replace(pt, "");
      __phone =
        "(" +
        __phone.substr(0, 2) +
        ") " +
        __phone.substr(2, 4) +
        "-" +
        __phone.substr(6, 4);
      return __phone;
    },

    maskCelPhone(v) {
      if (!v) {
        return "Sem registro";
      } else {
        v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
        v = v.replace(/^(\d{2})(\d)/g, "$1 $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
        return v.substr(0, 15);
      }
    },

    getDay(dateHour) {
      return !dateHour ? "" : dateHour.substring(0, 10);
    },

    getHour(dateHour) {
      return !dateHour ? "" : dateHour.substring(11, 16);
    },

    formatDayOfWeek(date) {
      if (date) {
        const dateSplit = date.split(" ");
        const str = dateSplit[0];
        const parts = str.split("/").map(Number);
        const nDate = new Date("20" + parts[2], parts[1] - 1, parts[0]);
        return weekNames[nDate.getDay()];
      }
    },

    getDayOfWeek(date) {
      if (!date) {
        return ""
      }
      const nDate = new Date(date.substring(0, 10).split("/").reverse().join("/"))
      const weekDate = nDate.getDay()

      return isNaN(weekDate) ? null : weekNames[weekDate]
    },
    testNullUndefinedEmpty(field) {
      return !field;
    },

    testFullName(word) {
      return !!word.match(/[A-Za-zÀ-ú][a-zà-ú]* [A-ZÀ-ú][a-zà-ú]*/);
    },

    onlyFirstLetterCapitalized(text) {
      if (this.testNullUndefinedEmpty(text)) {
          return;
      } else {
          let words = text.toLowerCase().split(" ");
          for (let a = 0; a < words.length; a++) {
              let w = words[a];
              words[a] = this.testNullUndefinedEmpty(w[0]) ? "" : w[0].toUpperCase() + w.slice(1);
          }
          return words.join(" ");
      }
  },

    testValidName(name) {
      if (!name || !this.testFullName(name) || name.trim().length < 5) {
        return false;
      }
      return true;
    },

    validateDate(date) {
      const regex =
        /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
      const [y, m, d] = date.split("-");
      const nDate = new Date(`${[m, d, y].join("-")} 00:00:000`);
      const dtString = nDate.toLocaleDateString();

      return regex.test(dtString);
    },

    validateBirthDate(date) {
      if (this.validateDate(date)) {
        const [d, m, y] = date.split("/").map((n) => parseInt(n, 10));
        const nd = new Date([m, d, y].join("/"));
        const dc = new Date();

        return !(((nd.getDate() !== parseInt(d)) && 
        (nd.getMonth() + 1 !== parseInt(m))) || dc.getTime() < nd.getTime())
      }
    },

    validateMinority(date) {
      const [d, m, y] = date.split(/\D/)

      if (this.validateBirthDate(`${y}-${m}-${d}`)) {
        const curr = new Date();

        if (curr.getFullYear() - parseInt(y) == 18) {
          //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
          if (curr.getMonth() < parseInt(m)) {
            return false;
          }
          if (curr.getMonth() === parseInt(m) && curr.getDate() < parseInt(d)) {
            //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
              return false;
          }
        }
        return true;
      }
      return false;
    },

    validateEmail(mail) {
      return !!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
    },

    validateCPF(cpf) {
      let isCpfOk = true;

      if (cpf == null || cpf == undefined) {
        return false;
      }

      const nCpf = cpf.replace(/\D/g, "");

      if (nCpf.toString().length !== 11 || /^(\d)\1{10}$/.test(nCpf)) {
        return false;
      }

      [9, 10].forEach((j) => {
        let summ = 0;
        let digit;
        nCpf
          .split(/(?=)/)
          .splice(0, j)
          .forEach(function (e, i) {
            summ += parseInt(e) * (j + 2 - (i + 1));
          });
        digit = summ % 11;
        digit = digit < 2 ? 0 : 11 - digit;

        if (digit != nCpf.substring(j, j + 1)) {
          isCpfOk = false;
        }
      });
      return isCpfOk;
    },

    validateCNPJ(cnpj) {
      if (!cnpj) {
        return false;
      }

      const nCnpj = cnpj.toString().replace(/[^\d]+/g, "");

      if (
        nCnpj.length < 14 ||
        new Array(10)
          .fill("")
          .map((_, idx) => idx.toString().repeat(14))
          .includes(nCnpj)
      ) {
        return false;
      }

      // Valida DVs
      const tamanho = nCnpj.length - 2;
      const digitos = nCnpj.substring(tamanho);
      let numeros = nCnpj.substring(0, tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = nCnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) {
        return false;
      }

      return true;
    },

    getChromeVersion() {
      const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

      return raw ? parseInt(raw[2], 10) : false;
    },

    maskCpf(cpf) {
      return cpf && cpf.length >= 14
        ? cpf
            .replace(/[^\d]/g, "")
            .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        : "";
    },

    maskZipcode(v) {
      return v ? v.replace(/D/g, "").replace(/^(\d{5})(\d)/, "$1-$2") : "";
    },

    validateZipCode(zipcode) {
      return (
        !this.testNullUndefinedEmpty(zipcode) &&
        /^[0-9]{5}-[0-9]{3}$/.test(zipcode)
      );
    },

    dateIsValidAndBeforeNow(date) {
      return !this.testNullUndefinedEmpty(date) && new Date(date) <= new Date();
    },
    getOnlyNumbers(value) {
      return value ? value.replace(/\D/g, "") : value;
    },
  },
};
