const needsAgDigit = [
  "Banco do Brasil",
  "Bradesco",
  "Rendimento",
  "Next",
  "Cresol",
  "Banco ABC Brasil",
  "VORTX DTVM LTDA",
]

const needsOpCode = [
  "Caixa Econômica Federal",
  "Caixa Econômica",
]

const unneedsAccDigit = [
  "CCR de São Miguel do Oeste",
  "Acesso Soluções de Pagamento",
  "Citibank",
  "Banco Capital S.A.",
  "Banco Ribeirão Preto",
  "Agibank",
  "Global SCM",
  "Rendimento",
]

const needsAccDigit = [
  "Acesso Soluções de pagamento",
  "Agibank",
  "ASAAS IP",
  "Banco Banese",
  "Banco BMG",
  "Banco BTG Pactual",
  "Banco C6",
  "Banco Capital S.A",
  "Banco da Amazonia",
  "Banco Daycoval",
  "Banco do Nordeste",
  "Banco Genial",
  "Banco Letsbank S.A",
  "Banco Omni",
  "Banco Original",
  "Banco Pan",
  "Banco Ribeirão Preto",
  "Banco Topazio",
  "BANCO XP S.A.",
  "Banestes",
  "Banpará",
  "Banrisul",
  "BCO SOFISA S.A.",
  "BNP Paribas Brasil",
  "BRB",
  "BRL Trust DTVM",
  "BS2",
  "Caixa Econômica",
  "CCR de São Miguel do Oeste",
  "Citibank",
  "CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA",
  "Cooperativa Central de Credito Noroeste Brasileiro",
  "Cora",
  "Credisan",
  "Crefisa",
  "Cresol",
  "Fitbank",
  "Gerencianet Pagamentos do Brasil",
  "Global SCM",
  "HR Digital Sociedade de Crédito Direto S.A",
  "Inter",
  "Itaú",
  "J17 - SCD S/A",
  "JP Morgan",
  "Juno",
  "Mercado Pago",
  "Mercantil do Brasil",
  "Modal",
  "Money Plus",
  "Neon",
  "Neon Pagamentos S.A. IP",
  "Neon/Votorantim",
  "Nubank",
  "Ótimo",
  "Pagseguro",
  "Pefisa",
  "Picpay",
  "Pinbank",
  "PJBank",
  "Polocred",
  "Safra",
  "Santander",
  "Sicoob",
  "Sicredi",
  "Singulare",
  "Stone",
  "SUMUP SCD S.A.",
  "Unicred",
  "Uniprime",
  "Uniprime Norte do Paraná",
  "Via Credi/Civia Cooperativa",
  "XP Investimentos",
]

export {
  needsAgDigit,
  needsOpCode,
  needsAccDigit,
  unneedsAccDigit
}
