<template src="./bankDetails.html" />

<script>
import { Money } from "v-money"
import ButtonSpinner from "vue-button-spinner"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/antd.css"

import mixins from "@/mixins/mixins.js"
import { useStore } from "@/store/store.js"
import { needsAgDigit, needsOpCode, needsAccDigit, unneedsAccDigit } from "@/constants/banks"

export default {
  mixins: [mixins],
  components: {
    Money,
    VueSlider,
    ButtonSpinner,
  },
  data() {
    return {
      updateRender: 0,
      bankAccountDigitValidateCaracter: "",
      nameOfResponsible: "",
      cpfOfResponsible: "",
      cnpj: "",
      disabledButtonRescueBalance: false,
      disabledButtonEdit: false,
      companyName: "",
      invalidField: {
        bank: false,
        bankAgencyNumber: false,
        bankAgencyDigit: false,
        bankAccountNumber: false,
        bankAccountDigit: false,
        nameOfResponsible: false,
        cpfOfResponsible: false,
        cnpj: false,
        companyName: false,
        zipcode: false,
        street: false,
        city: false,
        state: false,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      balanceSecondScreen: "",
      balanceSecondScreenWithTax: "",
      formatterMoney: "",
      balanceAvaliableForWithdraw: 0,
      showAccountDetails: false,
      activeAlert: true,
      bankSelected: {},
      bankOperation: "",
      bankOperationSelected: null,
      banks: [],
      banksOperationRules: [
        "001 - Conta Corrente de Pessoa Física",
        "002 - Conta Simples de Pessoa Física",
        "003 - Conta Corrente de Pessoa Jurídica",
        "013 - Poupança de Pessoa Física",
        "022 - Poupança de Pessoa Jurídica",
      ],
      bank: "",
      bankAgency: "Sem registro",
      bankAgencyNumber: null,
      bankAgencyDigit: null,
      showAgencyDigit: false,
      bankAccount: "Sem registro",
      bankAccountNumber: null,
      bankAccountDigit: null,
      showAccountDigit:true,
      accountIsVerified: false,
      street: "",
      city: "",
      state: "",
      showBankOperation: false,
      optionsSlider: {
        dotSize: 16,
        minRange: 1,
        min: 0,
        max: 10000,
        width: "300px",
        height: 4,
        direction: "ltr",
        interval: 0.01,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "focus",
        tooltipPlacement: "top",
        useKeyboard: false,
        enableCross: true,
        fixed: false,
      },
      zipcode: "",
      isLegalPerson: false,
      responsibleAccount: "",
      withdrawTax: 0,
      isLoading: false,
      status: "",
      disabledFieldStreet: true,
      bankNotSelected: false,
    }
  },
  computed: {
    filterBank() {
      return this.bankSelected && this.bankSelected.description
        ? this.bankSelected.description
        : ""
    },
    maskBankAgency () {
      return this.bankSelected && this.bankSelected.maskAgency
        ? this.bankSelected.maskAgency
        : "#########"
    },   
    maskBankAccount() {
      return this.bankSelected && this.bankSelected.maskAccount
        ? this.bankSelected.maskAccount
        : "#########"
    },
    bankAgencyValidateCaracter() {
      const agency = this.bankAgencyNumber

      if (agency && this.bankSelected) {
        const requireAgencyLength = parseInt(this.bankSelected.qtyCharAgency)

        if (agency.length !== requireAgencyLength) {
          return `- Agência Inválida : A agência deve conter
            ${requireAgencyLength}
            dígitos. Complete com zeros à esquerda se necessário.`
        }
      }
      return null
    },
    bankAccountValidateCaracter() {
      const account = this.bankAccountNumber

      if (account && this.bankSelected) {
        const requireAccountLength = parseInt(this.bankSelected.qtyCharAccount)

        if (account.length !== requireAccountLength) {
          return `- Conta Inválida : A conta deve conter 
            ${this.bankSelected.qtyCharAccount} dígitos. Complete com zeros à
            esquerda se necessário.`
        }
      }
      return null
    },
  },
  setup () {
    const store = useStore()

    return {
      isRescueBlocked: true,
      getGoogleAddress: (data) => store.dispatch("getGoogleAddress", data),
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },
  created () {
    this.disabledButtonRescueBalance = true
    this.disabledButtonEdit = true
  },
  async mounted () {
    await this.init()
  },
  methods: {
    async init () {
      await this.getWithdrawTax()
      await this.getBanks()
      await this.getAccountDetails()
      await this.getPersonalData()
      
      this.disabledButtonRescueBalance = false
      this.disabledButtonEdit = false
    },
    clearBankDetails () {
      this.bankAgencyNumber = null
      this.bankAgencyDigit = null
      this.bankOperation = ""
      this.bankOperationSelected = null
      this.bankAccountNumber = null
      this.bankAccountDigit = null
    },
    clearFieldsAddress () {
      this.street = null
      this.state = null
      this.city = null
    },
    hideBankDetailFields () {
      this.showAgencyDigit = false
      this.showBankOperation = false
      this.showAccountDigit = true
    },
    clearInvalidBankDetailFields () {
      this.invalidField.bank = false
      this.invalidField.bankAgencyNumber = false
      this.invalidField.bankAgencyDigit = false
      this.invalidField.bankAccountNumber = false
      this.invalidField.bankAccountDigit = false
      this.invalidField.bankOperation = false
    },
    forceUpdate () {
      this.updateRender += this.updateRender === 0 ? 1 : -1
    },
    async getAccountDetails () {
      await this.axios.get(
        this.$store.state.filooServer + "payment/account",
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ status, data }) => {
          if (status === 200) {
            this.fillInData(data)
          }
        })
        .catch((error) => {
          console.warn(error)
        });
    },
    getWithdrawTax () {
      this.axios.get(
        `${this.$store.state.filooServer}parameters/tax/client_withdraw_tax`,
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ status, data }) => {
          if (status === 200) {
            this.withdrawTax = data;
          }
        })
        .catch((error) => {
          console.warn(error)
        });
    },
    async getPersonalData () {
      try {
        const {status, data} = await this.axios.get(
          `${this.$store.state.filooServer}patient/personal-data`,
          { headers: { Authorization: this.$session.get("bearer") } }
        )
        if (status === 200) {
          this.cpfOfResponsible = data.cpf
          this.nameOfResponsible = data.name
        }
      } catch (error) {
        console.warn(error)
      }
    },
    validateFields () {
      this.invalidField = {
        ...this.invalidField,
        zipcode: !this.zipcode,
        street: !this.street,
        city: !this.city,
        state: !this.state,
      }

      //DADOS BANCO - ok
      if (!this.bank) {
        this.invalidField.bank = true;
      }

      //DADOS AGENCIA - ok
      if (!this.bankAgencyNumber || this.bankAgencyNumber.length > this.bankSelected.qtyCharAgency) {
        this.invalidField.bankAgencyNumber = true;
      }

      //DADOS AGENCIA DIGITO - ok
      if (needsAgDigit.includes(this.bank) && !this.bankAgencyDigit) {
        this.invalidField.bankAgencyDigit = true;
      }

      //DADOS CONTA - ok
      if (!this.bankAccountNumber && this.bankAccountNumber.length < this.bankSelected.qtyCharAccount) {
        this.invalidField.bankAccountNumber = true;
      }

      //DADOS CONTA DIGITO - ok
      if (needsAccDigit.includes(this.bank) && !this.bankAccountDigit) {
        this.invalidField.bankAccountDigit = true;
      }

      //DADOS OPERAÇÃO
      if (!this.bankOperation && needsOpCode.includes(this.bank)) {
        this.invalidField.bankOperation = true;
      }

      if (this.isLegalPerson) {
        this.invalidField.nameOfResponsible = this.testNullUndefinedEmpty(this.nameOfResponsible)

        this.invalidField.cpfOfResponsible = (
          this.testNullUndefinedEmpty(this.cpfOfResponsible) || !this.validateCPF(this.cpfOfResponsible)
        )

        this.invalidField.cnpj = (
          this.testNullUndefinedEmpty(this.cnpj) || !this.validateCNPJ(this.cnpj)
        )

        this.invalidField.companyName = this.testNullUndefinedEmpty(this.companyName)
      }

      if (Object.values(this.invalidField).filter(field => field).length) {
        this.showNotifyWarning(
          "Campos obrigatórios não preenchidos ou inválidos, preencha os campos corretamente e repita a operação.",
          6000
        )
      } else {
        this.$bvModal.show(
          "balance-and-financial-data-popup-confirm-change-account"
        )
      }
    },
    confirmSaveChangesAccount () {
      this.$vs.loading()

      const bank = [
        `${this.bankOperation}${this.bankAccountNumber}`,
        this.bankAccountDigit,
      ]

      const financialDetails = {
        address: this.street,
        bank: this.bankSelected.name,
        bank_account: bank.join("-"),
        bank_agency: this.bankAgencyDigit
          ? `${this.bankAgencyNumber}-${this.bankAgencyDigit}`
          : this.bankAgencyNumber,
        cep: this.zipcode.replace("-", ""),
        city: this.city,
        cpf: this.testNullUndefinedEmpty(this.cpfOfResponsible)
          ? ""
          : this.cpfOfResponsible.replace(/\D+/g, ""),
        is_legal_person:
          this.testNullUndefinedEmpty(this.isLegalPerson) == false ? true : false,
        resp_name: this.testNullUndefinedEmpty(this.nameOfResponsible)
          ? ""
          : this.nameOfResponsible,
        state:
          this.testNullUndefinedEmpty(this.state) == false
            ? this.state.toUpperCase()
            : null,
      }

      if (this.testNullUndefinedEmpty(this.isLegalPerson) == false) {
        financialDetails.company_name = this.testNullUndefinedEmpty(
          this.companyName
        )
          ? ""
          : this.companyName;
        financialDetails.cnpj = this.testNullUndefinedEmpty(this.cnpj)
          ? null
          : this.cnpj.replace(/\D+/g, "");
      }

      this.axios.post(
        this.$store.state.filooServer + "payment/account",
        financialDetails,
        { headers: { Authorization: this.$session.get("bearer") }}
      )
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close()
            this.$bvModal.hide("balance-and-financial-data-popup-confirm-change-account")
            this.showAccountDetails = false
            this.showNotifySuccess("Operação realizada", "Dados bancários foram atualizados com sucesso", 3000)
            this.clearBankDetails()
            this.clearInvalidBankDetailFields()
            this.hideBankDetailFields()
            this.init()
          }
        })
        .catch(() => {
          this.$vs.loading.close()
          this.$bvModal.hide("balance-and-financial-data-popup-confirm-change-account")
          this.showNotifyError("Não foi possível realizar esta operação", 8000)
        })
    },
    openPopupCorreios () {
      this.$bvModal.show("balance-and-financial-data-popup-correios")
    },
    fillInData (data) {
      if (data.details) {
        const { details } = data

        this.disabledButtonRescueBalance = data.blocked_by_admin
        this.nameOfResponsible = details.resp_name || this.nameOfResponsible
        this.accountIsVerified = data.hasOwnProperty("is_verified")
          ? data.is_verified
          : "Sem registro"

        this.balanceAvaliableForWithdraw = data.balance_avaliable_for_withdraw

        this.companyName = details.company_name || ""
        this.cnpj = details.cnpj || ""
        this.isLegalPerson = details.is_legal_person || ""
        this.street = details.address || ""
        this.city = details.city || ""
        this.zipcode = details.cep || ""
        this.state = details.state || ""
        this.bank = details.bank || ""

        this.bankAgency = details.bank_agency
          ? details.bank_agency.split("-").filter(v => v).join("-")
          : ""
        this.bankAccount = details.bank_account
          ? details.bank_account.split("-").map(v => v.replace(/\D+/g, "")).filter(v => v).join("-")
          : ""
      }
    },
    async getBanks () {
      await this.axios.get(
        this.$store.state.filooServer + "payment/banks",
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ status, data }) => {
          this.banks = status === 200 ? data : []
        })
        .catch((error) => {
          console.warn(error)
        })
    },
    clickBtnEditAccount () {      
      const bankAccount = this.bankAccount.split("-")
      const bankAgency = this.bankAgency.split("-")
      const bankName = this.bank
      
      this.bankSelected.name = bankName
      this.bankAccountNumber = bankAccount[0]
      this.bankAgencyNumber = bankAgency[0]
      
      if (!this.testNullUndefinedEmpty(bankAccount[1])) {
        this.bankAccountDigit = bankAccount[1]
      }
      
      if (!this.testNullUndefinedEmpty(bankAgency[1])) {
        this.bankAgencyDigit = bankAgency[1]
      }

      this.showAgencyDigit = needsAgDigit.includes(bankName)
      this.showAccountDigit = !unneedsAccDigit.includes(bankName)
      this.showAccountDetails = true
    },
    clickBtnCancel () {
      this.zipcode = null
      this.street = null
      this.state = null
      this.city = null

      this.clearBankDetails()
      this.hideBankDetailFields()

      this.invalidField = {
        bank: false,
        bankAgencyNumber: false,
        bankAgencyDigit: false,
        bankAccountNumber: false,
        bankAccountDigit: false,
        nameOfResponsible: false,
        cpfOfResponsible: false,
        cnpj: false,
        companyName: false,
        zipcode: false,
        street: false,
        city: false,
        state: false,
      }

      this.showAccountDetails = false
      this.getPersonalData()
      this.getAccountDetails()
    },
    rescueBalance () {
      this.responsibleAccount = this.$session.get("name")
      this.balanceSecondScreen = parseFloat(this.balanceAvaliableForWithdraw)
      const moneyIntFormat = parseFloat(this.balanceSecondScreen)
      this.optionsSlider.max = moneyIntFormat
      this.optionsSlider.min = 5
      this.formatterMoney = (moneyIntFormat) =>
        `R$${("" + moneyIntFormat).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
      this.$bvModal.show("balance-and-financial-data-popup-rescue")
    },
    clearButtonLoading () {
      setTimeout(() => {
        this.isLoading = false
        this.status = ""
      }, 1600)
    },
    widthdraw () {
      this.isLoading = true
      this.balanceSecondScreenWithTax = this.balanceSecondScreen
      if (this.balanceSecondScreen < 11) {
        this.$vs.notify({
          title: "Atenção",
          text: "Valor mínimo permitido é R$11,00.",
          color: "warning",
          position: "top-right",
        })
        this.clearButtonLoading()
      } else if (
        this.balanceSecondScreenWithTax > this.balanceAvaliableForWithdraw
      ) {
        this.$vs.notify({
          title: "Atenção",
          text:
            "Seu saldo atual é menor do que o valor solicitado mais a taxa de saque de " +
            this.maskMoney(this.withdrawTax),
          color: "warning",
          position: "top-right",
        })
        this.clearButtonLoading()
      } else {
        var amount = {
          amount: this.balanceSecondScreenWithTax,
        }
        this.axios
          .post(
            this.$store.state.filooServer + "payment/account/withdraw",
            amount,
            {
              headers: {
                Authorization: this.$session.get("bearer"),
              },
            }
          )
          .then(({ status }) => {
            if (status === 200) {
              this.clearButtonLoading()
              this.showNotifySuccess("Operação realizada", "Seu resgate foi realizado com sucesso!", 4000)
              this.$bvModal.hide("balance-and-financial-data-popup-rescue")
              this.init()
              this.$store.commit(
                "UPDATE_CUSTOMER_BALANCE",
                !this.$store.state.updatedCustomerBalance
              )
            }
          })
          .catch(() => {
            this.clearButtonLoading()
            this.init()
            this.$store.commit(
              "UPDATE_CUSTOMER_BALANCE",
              !this.$store.state.updatedCustomerBalance
            )
            this.$nextTick(() => {
              this.showNotifyError("Não foi possível realizar esta operação", 8000)
            })
          })
      }
    },
    async searchDataAddress () {
      let validZip = !!this.zipcode && this.zipcode.length === 9
      let zipSearch = false
      
      if (validZip) {
        const response = await this.getAddressByZipcode(this.zipcode)
        validZip = response && response.address
        
        zipSearch = true
        this.disabledFieldStreet = validZip && !!this.street
        
        if (validZip) {
          this.street = response.address.street
          this.state = response.address.state
          this.city = response.address.city
        } 
      }
      if (!validZip) {
        await this.clearFieldsAddress()
        this.invalidField.zipcode = true
        this.invalidField.city = true
        this.invalidField.street = true
        this.invalidField.state = true

        if (zipSearch) {
          this.showNotifyWarning(
            `Não foi possível localizar o CEP digitado, forneça um novo cep para
            dar sequência ao cadastro dos dados bancários.`,
            6000
          )
        }
      }
    },
  },
  watch: {
    bankSelected (newVal) {
      const name = newVal ? newVal.name : null

      this.clearBankDetails()
      this.clearInvalidBankDetailFields()
      this.hideBankDetailFields()
      this.forceUpdate()
      
      this.showAgencyDigit = needsAgDigit.includes(name)
      this.showBankOperation = needsOpCode.includes(name)
      this.showAccountDigit = !unneedsAccDigit.includes(name)
      this.bank = name
    },

    bankOperationSelected (codeOperation) {
      this.bankOperaion = ""
      if (codeOperation) {
        this.bankOperation = codeOperation.substr(0, 3)
      }
    },
    zipcode () {
      this.invalidField.zipcode = false
    },
    state () {
      this.invalidField.state = false
    },
    city () {
      this.invalidField.city = false
    },
    street () {
      this.invalidField.street = false
    },
    bankAgencyNumber () {
      this.invalidField.bankAgencyNumber = false
    },
    bankAgencyDigit () {
      this.invalidField.bankAgencyDigit = false
    },
    bankAccountNumber () {
      this.invalidField.bankAccountNumber = false
    },
    bankAccountDigit (newVal) {
      this.invalidField.bankAccountDigit = false

      this.bankAccountDigitValidateCaracter = this.bankSelected && !newVal
        ? "- Dígito Inválido : A conta deve conter 1 dígitos. Complete com zeros à esquerda se necessário."
        :""
    },
    bankOperation() {
      this.invalidField.bankOperation = false
    },
    cpfOfResponsible () {
      this.invalidField.cpfOfResponsible = false
    },
    nameOfResponsible () {
      this.invalidField.nameOfResponsible = false
    },
    cnpj () {
      this.invalidField.cnpj = false
    },
    companyName () {
      this.invalidField.companyName = false
    },
  },
}
</script>

<style lang="scss" src="./bankDetails.scss" scoped />
