const weekNames = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
]

const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
]

const shortWeekNames = weekNames.map((name) => name.substring(0, 3))
const initialWeekNames = weekNames.map((name) => name.substring(0, 1))

const shortMonthNames = monthNames.map((name) => name.substring(0, 3))
const initialMonthNames = monthNames.map((name) => name.substring(0, 1))

export {
  weekNames,
  monthNames,
  shortWeekNames,
  initialWeekNames,
  shortMonthNames,
  initialMonthNames
}